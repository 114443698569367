import React from "react";
import Container from "../../components/Container";
import { Typography, Box, IconButton, useTheme } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import {
  MANUALS_AND_CODE_SEARCH_URL,
  OEM_360_ASSIST_URL,
  OPERATIONS_AND_MAINTENANCE_ASSISTANT_URL,
  VOICE_GUIDED_FIELD_ASSISTANT_URL,
  VOICE_INSPECTION_GUIDANCE_URL,
} from "../../Routes";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background:
      "linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  productCard: {
    padding: 12,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 50,
    background: "#fff",
    boxShadow: "2px 2px 10px 0px rgba(169, 61, 253, 0.16)",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 32,
    },
  },
}));

function Products() {
  const intelligentAISearchProducts = [
    {
      key: 0,
      label: "Operation & Maintenance Assistant",
      icon: "/operations_and_maintainance/ai_operations_and_maintainance.png",
      path: OPERATIONS_AND_MAINTENANCE_ASSISTANT_URL,
    },
    {
      key: 1,
      label: "Manuals & Code Search",
      icon: "/manuals_and_code_search/ai_industries_manual.png",
      path: MANUALS_AND_CODE_SEARCH_URL,
    },
    // {
    //   key: 2,
    //   label: "OEM 360 Assist",
    //   icon: "/oem/ai_manufacturing_oem.png",
    //   path: OEM_360_ASSIST_URL,
    // },
  ];

  const voiceAIProducts = [
    {
      key: 0,
      label: "Voice Guided Field Assistant",
      icon: "/voice_guided_assistant/ai_voice_assistant.png",
      path: VOICE_GUIDED_FIELD_ASSISTANT_URL,
    },
    {
      key: 1,
      label: "Voice Inspection Guidance",
      icon: "/voice_inspection/ai_inspection.png",
      path: VOICE_INSPECTION_GUIDANCE_URL,
    },
  ];
  const theme = useTheme();
  const { classes } = useStyles();
  return (
    <Container wrapperStyle={{ background: "white" }}>
      <Typography sx={{ typography: { xs: "h3", md: "h2" } }}>
        Our Products
      </Typography>
      <Box mb={{ xs: 3, md: 7 }} />
      <Typography
        className={classes.gradientText}
        sx={{ typography: { xs: "h5", md: "h4" } }}>
        Intelligent AI Search
      </Typography>
      <Box mb={{ xs: 2, md: 4 }} />
      {intelligentAISearchProducts.map((product, i) => {
        const { key, label, icon, path } = product;
        return (
          <Box
            key={key}
            component={NavLink}
            to={path}
            className={classes.productCard}
            mb={{ xs: 2, md: 4 }}>
            <Box
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              flexDirection={{ xs: "column", md: "row" }}>
              <img src={icon} alt={label} width={80} height={80} />
              <Box mr={{ xs: 0, md: 2 }} mb={{ xs: 2, md: 0 }} />
              <Typography
                sx={{ typography: { xs: "h6", md: "h5" } }}
                color={"textPrimary"}
                textAlign={{ xs: "center", md: "left" }}>
                {label}
              </Typography>
            </Box>
            <ArrowForwardIos
              sx={{
                color: theme.palette.text.secondary,
                fontSize: 32,
                display: { xs: "none", md: "initial" },
              }}
            />
          </Box>
        );
      })}
      {/* <Box mb={{ xs: 2, md: 6 }} />
      <Typography
        sx={{ typography: { xs: "h5", md: "h4" } }}
        className={classes.gradientText}>
        Unified Voice AI
      </Typography>
      <Box mb={{ xs: 2, md: 4 }} />
      {voiceAIProducts.map((product, i) => {
        const { key, label, icon, path } = product;
        return (
          <Box
            key={key}
            component={NavLink}
            to={path}
            className={classes.productCard}
            mb={{
              md: i !== voiceAIProducts.length - 1 ? 4 : 0,
              xs: i !== voiceAIProducts.length - 1 ? 2 : 0,
            }}>
            <Box
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              flexDirection={{ xs: "column", md: "row" }}>
              <img src={icon} alt={label} width={80} height={80} />
              <Box mr={{ xs: 0, md: 2 }} mb={{ xs: 2, md: 0 }} />
              <Typography
                sx={{ typography: { xs: "h6", md: "h5" } }}
                color={"textPrimary"}
                textAlign={{ xs: "center", md: "left" }}>
                {label}
              </Typography>
            </Box>
            <ArrowForwardIos
              sx={{
                color: theme.palette.text.secondary,
                fontSize: 32,
                display: { xs: "none", md: "initial" },
              }}
            />
          </Box>
        );
      })} */}
    </Container>
  );
}

export default Products;
