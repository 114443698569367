import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Typography,
  Box,
  Button,
  InputBase,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { makeStyles } from "tss-react/mui";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import GradientBorderButtonWrapper from "./GradientBorderButtonWrapper";
import Container from "./Container";

const useStyles = makeStyles()((theme) => ({
  textField: {
    border: "1px solid #2D2E33",
    padding: 12,
    borderRadius: 8,
    "&:hover": {
      borderColor: "whitesmoke",
    },
  },
}));

const validationSchema = yup.object({
  name: yup.string().trim().required("Name is required."),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address.")
    .required("Email is required."),
});

export const uid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export default function Form() {
  const { classes } = useStyles();
  const [success, setSuccess] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const initialValues = {
    name: "",
    email: "",
    company: "",
  };

  async function onSubmit(values, { resetForm }) {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://api.staging.ravenapp.dev/copilot/demo_request",
        values
      );

      setIsLoading(false);

      // Check if the response is ok
      // if (!response) {
      //   setSuccess(false);
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }

      setSuccess(true);

      await timeout(2300);
    } catch (e) {
      console.log(e);
    }

    resetForm();
    setSuccess(null);
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Container
      containerStyle={{
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Box sx={{ width: { xs: "100%", md: "50%" } }}>
        <Typography
          sx={{
            color: "white",
            typography: { xs: "h3", md: "h2" },
          }}
          textAlign={"center"}>
          Have a Use-case? Let's Connect
        </Typography>
        <Box mb={{ xs: 4, md: 6 }} />
        <form>
          <InputBase
            fullWidth
            name="name"
            placeholder="Name"
            className={classes.textField}
            inputProps={{ style: { color: "whitesmoke" } }}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
          />
          {formik.touched.name && formik.errors.name && (
            <FormHelperText error sx={{ ml: 1 }}>
              {formik.errors.name}
            </FormHelperText>
          )}
          <Box mb={3} />
          <InputBase
            fullWidth
            name="email"
            placeholder="Your Email"
            className={classes.textField}
            inputProps={{ style: { color: "whitesmoke" } }}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          {formik.touched.email && formik.errors.email && (
            <FormHelperText error sx={{ ml: 1 }}>
              {formik.errors.email}
            </FormHelperText>
          )}
          <Box mb={3} />
          <InputBase
            fullWidth
            multiline
            maxRows={4}
            name="company"
            placeholder="How can we help"
            className={classes.textField}
            inputProps={{
              style: { color: "whitesmoke", paddingTop: 4, paddingBottom: 5 },
            }}
            value={formik.values.company}
            onChange={formik.handleChange}
            // error={formik.touched.company && Boolean(formik.errors.company)}
            // helperText={formik.touched.company && formik.errors.company}
          />
          {/* {formik.touched.company && formik.errors.company && (
            <FormHelperText error sx={{ ml: 1 }}>
              {formik.errors.company}
            </FormHelperText>
          )} */}
          <Box mb={3} />
          {success !== null ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}>
              {success ? (
                <>
                  <CheckCircleIcon
                    sx={{ fontSize: 50, color: "green", mr: 2 }}
                  />
                  <Typography variant="h5" color={"white"}>
                    Success! You will hear from us shortly.
                  </Typography>
                </>
              ) : (
                <>
                  <CancelIcon sx={{ fontSize: 50, color: "red", mr: 2 }} />
                  <Typography variant="h5" color={"white"}>
                    Try again!
                  </Typography>
                </>
              )}
            </Box>
          ) : (
            // <GradientBorderButtonWrapper>
            //   <Button
            //     sx={{ height: 54, borderRadius: 2 }}
            //     variant="contained"
            //     color="secondary"
            //     size="large"
            //     type="submit"
            //     fullWidth
            //     onClick={formik.handleSubmit}>
            //     {isLoading ? (
            //       <>
            //         <CircularProgress sx={{ color: "white" }} />
            //       </>
            //     ) : (
            //       <>Submit</>
            //     )}
            //   </Button>
            // </GradientBorderButtonWrapper>
            <GradientBorderButtonWrapper>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                size="large"
                onClick={formik.handleSubmit}>
                {isLoading ? (
                  <>
                    <CircularProgress sx={{ color: "white" }} />
                  </>
                ) : (
                  <>Submit</>
                )}
              </Button>
            </GradientBorderButtonWrapper>
          )}
        </form>
      </Box>
    </Container>
  );
}
