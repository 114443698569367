import React from "react";
import Container from "../../../components/Container";
import {
  Button,
  Grid,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import GradientBorderButtonWrapper from "../../../components/GradientBorderButtonWrapper";
import Demo from "./Demo";

function Hero({
  title,
  subtitle,
  img,
  btnText = "Schedule a Demo",
  onClick = () => {},
  isDemo = false,
}) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container wrapperStyle={{ marginTop: 78.5 }}>
      <Grid container alignItems={"center"} rowSpacing={9} columnSpacing={9}>
        <Grid item md={7} sm={12}>
          <Typography
            textAlign={{ xs: "center", md: "left" }}
            sx={{
              color: "white",
              typography: { xs: "h3", lg: "h2", xl: "h1" },
            }}
            component={"h1"}>
            {title}
          </Typography>
          <Box mb={{ xs: 4, md: 7 }} />
          <Typography
            textAlign={{ xs: "center", md: "left" }}
            sx={{
              display: "block",
              color: "white",
              typography: { xs: "h6", md: "h5" },
            }}
            component={"subtitle"}>
            {subtitle}
          </Typography>
          <Box mb={6} />
          <GradientBorderButtonWrapper
            style={{ display: { xs: "block", md: "inline-block" } }}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={onClick}>
              {btnText}
            </Button>
          </GradientBorderButtonWrapper>
        </Grid>
        <Grid item md={5} sm={12}>
          <img
            src={isXS ? `${img}_mobile.png` : `${img}.png`}
            alt="hero"
            width={"100%"}
          />
        </Grid>
      </Grid>
      {isDemo && <Demo />}
    </Container>
  );
}

export default Hero;
