import React from "react";
import Container from "../../components/Container";
import { Typography, Box, Button, Grid } from "@mui/material";
import GradientBorderButtonWrapper from "../../components/GradientBorderButtonWrapper";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    textAlign: "center",
    width: "100%",
    background:
      "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function About() {
  const { classes } = useStyles();
  return (
    <Container>
      <Typography
        className={classes.gradientText}
        sx={{ typography: { xs: "h3", md: "h2" } }}>
        About Raven AI
      </Typography>
      <Box mb={6} />
      <Grid container rowSpacing={3} columnSpacing={3} alignItems={"center"}>
        <Grid item md={6}>
          <Typography
            fontWeight={400}
            sx={{ color: "white", typography: { xs: "body1", md: "h6" } }}>
            Raven AI revolutionizes industrial and business operations with
            advanced AI. Our flagship products, Unified Voice AI Platform and
            Intelligent AI Search, enhance data interaction and task execution,
            boosting efficiency and compliance.
            <br /> Based in San Francisco and Bangalore, we are your partners in
            digital transformation.
          </Typography>
          <Box mb={6} />
          <GradientBorderButtonWrapper
            style={{ display: { xs: "block", md: "inline-block" } }}>
            <Button fullWidth variant="contained" size="large">
              Learn More
            </Button>
          </GradientBorderButtonWrapper>
        </Grid>
        <Grid item md={6}>
          <img src="/raven_ai_logo_dark.png" width={"100%"} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default About;
