import React from "react";
import { Typography, Box, Grid, Button, Chip, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function ImageComponent({ image, isXS }) {
  return (
    <img
      src={image}
      alt="feature"
      width={isXS ? "100%" : 280}
      style={{
        borderRadius: "16.61px 16.61px 0px 0px",
        boxShadow:
          "-0.792px -0.792px 6.34px 0px rgba(6, 146, 248, 0.50), 2.323px 4.646px 31.7px 0px rgba(169, 61, 253, 0.40)",
      }}
    />
  );
}

function TextComponent({ title, description, icon, isXS, orientation }) {
  return (
    <Box
      sx={{
        flex: 1,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        mb: 3,
        alignItems: { xs: "center", md: orientation },
      }}
    >
      <Box display={"inline-flex"} mb={{ xs: 1.5, md: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background:
              "linear-gradient(292deg, rgba(115, 38, 104, 0.80) 0.1%, rgba(64, 38, 115, 0.80) 50.05%, rgba(47, 38, 115, 0.80) 100%)",
            py: 1,
            px: 1.5,
            borderRadius: 25,
          }}
        >
          {icon}
          <Box mr={1} />
          <Typography color={"white"} fontWeight={500}>
            {title}
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          lineHeight: "150% !important",
          color: "white",
          textAlign: { xs: "center", md: "left" },
          textShadow: "#212121 1px 0 10px",
          typography: { md: "h4", xs: "h5" },
        }}
      >
        {description}
      </Typography>
    </Box>
  );
}

function Feature({ feature, isLast }) {
  const { classes } = useStyles();
  const { key, title, description, image, icon } = feature;
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={{ xs: "column", sm: "row" }}
      px={{ xs: 3, sm: 6, md: 9 }}
      pt={4}
      mb={{ xs: isLast ? 0 : 9, md: isLast ? 0 : 25 }}
      sx={{
        background: {
          xs:
            key % 2 !== 0
              ? "url('/general/feature_left_text_xs.png') no-repeat"
              : "url('/general/feature_right_text_xs.png') no-repeat",
          sm:
            key % 2 !== 0
              ? "url('/general/feature_left_text_md.png') no-repeat"
              : "url('/general/feature_right_text_md.png') no-repeat",
          lg:
            key % 2 !== 0
              ? "url('/general/feature_left_text_lg.png') no-repeat"
              : "url('/general/feature_right_text_lg.png') no-repeat",
        },
        backgroundSize: {
          xs: "100% 100%",
          sm: "100% 100%",
          lg: "100% 100%",
        },
        alignItems: "center",
      }}
    >
      {isXS ? (
        <>
          <TextComponent title={title} description={description} icon={icon} isXS={true} />
          <Box mb={4} />
          <ImageComponent image={image} isXS={true} />
        </>
      ) : (
        <>
          {key % 2 !== 0 ? (
            <>
              <ImageComponent image={image} />
              <Box mr={{ sm: 6, md: 12 }} mb={{ sm: 4, md: 0 }} />
              <TextComponent
                title={title}
                description={description}
                icon={icon}
                orientation={"start"}
              />
            </>
          ) : (
            <>
              <TextComponent
                title={title}
                description={description}
                icon={icon}
                orientation={"start"}
              />
              <Box mr={{ sm: 6, md: 9 }} mb={{ sm: 4, md: 0 }} />
              <ImageComponent image={image} />
            </>
          )}

          {/* <>
            <TextComponent title={title} description={description} icon={icon} />
            <Box mr={{ sm: 6, md: 9 }} mb={{ sm: 4, md: 0 }} />
            <ImageComponent image={image} />
          </> */}
        </>
      )}
    </Box>
  );
}

export default Feature;
