import React, { useState } from "react";
import {
  Box,
  Link,
  IconButton,
  Button,
  Divider,
  MenuItem,
  ListItemText,
  ListItemIcon,
  useTheme,
} from "@mui/material";
import { East, Close, ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import GradientBorderButtonWrapper from "../GradientBorderButtonWrapper";
import { links } from "./Navbar";
import { makeStyles } from "tss-react/mui";
import { MANUALS_AND_CODE_SEARCH_URL } from "../../Routes";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    display: "inline-block",
    background:
      "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function MobileViewMenu({ onClose }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.background.dark,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        height: "100dvh",
        zIndex: 1999,
        display: "flex",
        flexDirection: "column",
      }}>
      <Box
        mb={2}
        px={3}
        py={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Link
          component={NavLink}
          to={MANUALS_AND_CODE_SEARCH_URL}
          underline="none">
          <img src={"/logo/logo.png"} width={124} height={40} />
        </Link>
        <IconButton
          sx={{ background: "rgba(255, 255, 255, 0.08)" }}
          onClick={onClose}>
          <Close sx={{ color: "white" }} />
        </IconButton>
      </Box>
      <Box flex={1} overflow={"scroll"} px={2}>
        {links.map((link) => {
          const { key } = link;
          return <NavItem key={key} link={link} onClose={onClose} />;
        })}
      </Box>
      <Box p={3}>
        <GradientBorderButtonWrapper>
          <Button fullWidth variant="contained">
            Schedule a Demo
          </Button>
        </GradientBorderButtonWrapper>
      </Box>
    </Box>
  );
}

function NavItem({ link, onClose }) {
  const { classes } = useStyles();
  const { key, label, path, children } = link;
  const [open, setOpen] = useState(true);

  return (
    <>
      {children ? (
        <Box key={key}>
          <Button
            onClick={() => setOpen(!open)}
            variant="text"
            color="primary"
            endIcon={
              open ? (
                <ArrowDropUp fontSize="small" sx={{ color: "#EF8FE2" }} />
              ) : (
                <ArrowDropDown fontSize="small" sx={{ color: "#EF8FE2" }} />
              )
            }>
            <span className={classes.gradientText}>{label}</span>
          </Button>
          {open && (
            <>
              {children.map((l) => {
                const { key, label, path, icon } = l;
                return (
                  <NavLink
                    onClick={onClose}
                    key={key}
                    to={path}
                    style={{ textDecoration: "none", color: "white" }}>
                    <MenuItem sx={{ paddingX: 1 }}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText sx={{ color: "white" }}>
                        {label}
                      </ListItemText>
                    </MenuItem>
                  </NavLink>
                );
              })}
            </>
          )}
          <Divider
            sx={{ marginY: 1, borderColor: "rgba(255, 255, 255, 0.12)" }}
          />
        </Box>
      ) : (
        <NavLink
          key={key}
          to={path}
          onClick={onClose}
          style={{ textDecoration: "none" }}>
          <Button
            size="large"
            variant="text"
            endIcon={<East sx={{ color: "#EF8FE2" }} />}>
            <span className={classes.gradientText}>{label}</span>
          </Button>
        </NavLink>
      )}
    </>
  );
}

export default MobileViewMenu;
