import React from "react";
import {
  Box,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Container from "../Container";
import Logo from "../Logo";
import {
  MANUALS_AND_CODE_SEARCH_URL,
  OEM_360_ASSIST_URL,
  OPERATIONS_AND_MAINTENANCE_ASSISTANT_URL,
  VOICE_GUIDED_FIELD_ASSISTANT_URL,
  VOICE_INSPECTION_GUIDANCE_URL,
} from "../../Routes";
import { NavLink } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

export const products = [
  {
    key: "operations&maintenance_assistant",
    label: "Operations & Maintenance Assistant",
    path: OPERATIONS_AND_MAINTENANCE_ASSISTANT_URL,
    icon: (
      <img
        src="/operations_and_maintainance/ai_operations_and_maintainance.png"
        alt="icon"
        width={24}
        height={24}
      />
    ),
    type: "internal",
  },
  {
    key: "manuals&code_search",
    label: "Manuals QnA Chatbot",
    path: MANUALS_AND_CODE_SEARCH_URL,
    icon: (
      <img
        src="/manuals_and_code_search/ai_industries_manual.png"
        alt="icon"
        width={24}
        height={24}
      />
    ),
    type: "internal",
  },
  // {
  //   key: "oem_360_assist",
  //   label: "OEM 360 Assist",
  //   path: OEM_360_ASSIST_URL,
  //   icon: (
  //     <img
  //       src="/oem/ai_manufacturing_oem.png"
  //       alt="icon"
  //       width={24}
  //       height={24}
  //     />
  //   ),
  //   type: "internal",
  // },
  // {
  //   key: "voice_guided_field_assistant",
  //   label: "Voice Guided Field Assistant",
  //   path: VOICE_GUIDED_FIELD_ASSISTANT_URL,
  //   icon: (
  //     <img
  //       src="/voice_guided_assistant/ai_voice_assistant.png"
  //       alt="icon"
  //       width={24}
  //       height={24}
  //     />
  //   ),
  //   type: "internal",
  // },
  // {
  //   key: "voice_inspection_guidance",
  //   label: "Voice Inspection Guidance",
  //   path: VOICE_INSPECTION_GUIDANCE_URL,
  //   icon: (
  //     <img
  //       src="/voice_inspection/ai_inspection.png"
  //       alt="icon"
  //       width={24}
  //       height={24}
  //     />
  //   ),
  //   type: "internal",
  // },
];

const useStyles = makeStyles()((theme) => ({
  footerItem: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      alignItems: "center",
    },
  },
}));

function Footer() {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <Container
      wrapperStyle={{
        backgroundColor: theme.palette.background.dark,
      }}>
      <Grid container rowSpacing={5} columnSpacing={5}>
        <Grid item xs={12} sm={6} lg>
          <Box className={classes.footerItem}>
            <Link
              component={NavLink}
              to={MANUALS_AND_CODE_SEARCH_URL}
              underline="none">
              <Logo />
            </Link>
            <Box mb={1} />
            <Typography variant="subtitle2" sx={{ color: "#F5F5F5" }}>
              © Raven.dev, Inc.2024
            </Typography>
            <Box mb={1} />
            <Typography variant="subtitle2" sx={{ color: "#F5F5F5" }}>
              Based in San Francisco
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg>
          <Box className={classes.footerItem}>
            <Typography variant="subtitle2" sx={{ color: "#F5F5F5" }}>
              Backed by
            </Typography>
            <img src="/yc-white.svg" alt="yc" width={200} height={80} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg>
          <Box className={classes.footerItem}>
            <Typography variant="subtitle2" sx={{ color: "#F5F5F5" }}>
              Products
            </Typography>
            <Box mb={1} />
            {products.map((product) => {
              const { key, label, path } = product;
              return (
                <Link
                  color={"secondary"}
                  key={key}
                  component={NavLink}
                  to={path}
                  sx={{
                    display: "block",
                    marginBottom: 1,
                    "&:hover": {
                      color: "white",
                    },
                  }}>
                  {label}
                </Link>
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg>
          <Box className={classes.footerItem}>
            <Typography variant="subtitle2" sx={{ color: "#F5F5F5" }}>
              Contact us at
            </Typography>
            <Box mb={1} />
            <Link
              color={"secondary"}
              onClick={() =>
                (window.location = "mailto:priyansh@startraven.com")
              }>
              priyansh@startraven.com
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Footer;
