import { createContext, useContext, useState } from "react";

const FormProviderContext = createContext("");

function FormProvider({ children }) {
  const [open, setOpen] = useState(false);

  const handleOpenForm = () => {
    setOpen(true);
  };

  const handleCloseForm = () => {
    setOpen(false);
  };
  return (
    <FormProviderContext.Provider
      value={{ open, handleOpenForm, handleCloseForm }}>
      {children}
    </FormProviderContext.Provider>
  );
}

export function useFormProvider() {
  return useContext(FormProviderContext);
}

export default FormProvider;
