import React from "react";
import {
  useTheme,
  Box,
  Typography,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { LinkedIn, Twitter } from "@mui/icons-material";
import Container from "../../components/Container";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background:
      "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

const mock = [
  {
    fname: "Priyansh",
    lname: "Srivastava",
    title: (
      <Box display={"flex"} alignItems={"center"} mt={1}>
        <Typography sx={{ color: "white" }}>Co-founder</Typography>
      </Box>
    ),
    avatar: "/team/Priyansh.png",
    linkedin: "https://www.linkedin.com/in/priyanshs/",
    twitter: "https://twitter.com/proy31",
  },
  {
    fname: "Sriyansh",
    lname: "Srivastava",
    title: (
      <Box display={"flex"} alignItems={"center"} mt={1}>
        <Typography sx={{ color: "white" }}>Co-founder</Typography>
      </Box>
    ),
    avatar: "/team/Sriyansh.png",
    linkedin: "https://www.linkedin.com/in/sriyansh-srivastava-13b381109/",
    twitter: "https://twitter.com/sriyansh_s",
  },
  {
    fname: "Suryansh",
    lname: "Srivastava",
    title: (
      <Box display={"flex"} alignItems={"center"} mt={1}>
        <Typography sx={{ color: "white" }}>Product Designer</Typography>
      </Box>
    ),
    avatar: "/team/Suryansh.png",
    linkedin: "https://www.linkedin.com/in/suryansh-srivastava/",
  },
  {
    fname: "Rohith",
    lname: "Guntha",
    title: (
      <Box display={"flex"} alignItems={"center"} mt={1}>
        <Typography sx={{ color: "white" }}>Software Engineer</Typography>
      </Box>
    ),
    avatar: "/team/Rohith.png",
    linkedin: "https://www.linkedin.com/in/guntha-rohith-355402182/",
  },
];

function Team() {
  const { classes } = useStyles();
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("md"));

  const personImage = (item) => (
    // <Box
    //   sx={{ borderRadius: 2, overflow: "hidden" }}
    //   minWidth={150}
    //   maxWidth={300}
    //   width={{ xs: 200, md: 250, lg: 300 }}
    //   height={{ xs: 230, md: 260, lg: 333 }}
    // >
    <img
      width={"100%"}
      height={"100%"}
      src={item.avatar}
      style={{ borderRadius: 8 }}
    />
    // </Box>
  );

  const role = (item, direction) => (
    <Box
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      ml={{
        xs: direction === "left" ? 2 : 0,
        md: direction === "left" ? 4 : 0,
      }}
      mr={{
        xs: direction === "right" ? 2 : 0,
        md: direction === "right" ? 4 : 0,
      }}
      justifyContent="center"
      alignItems={direction === "left" ? "flex-start" : "flex-end"}>
      <Box
        // width={100}
        // height={4}
        // sx={{
        //   background:
        //     "linear-gradient(73deg, #BF40AE 44.21%, #6B40BF 49.24%, #4E40BF 54.28%)",
        // }}
        mb={2}
        ml={direction === "left" ? -7 : 0}
        mr={direction === "right" ? -7 : 0}
        zIndex={1}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="90"
          height="5"
          viewBox="0 0 90 5"
          fill="none">
          <path
            d="M0.777344 2.23483H89.0767"
            stroke="url(#paint0_linear_9673_43978)"
            stroke-width="3.71787"
          />
          <defs>
            <linearGradient
              id="paint0_linear_9673_43978"
              x1="89.0766"
              y1="3.23483"
              x2="89.0486"
              y2="1.08292"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#BF40AE" />
              <stop offset="0.5" stop-color="#6B40BF" />
              <stop offset="1" stop-color="#4E40BF" />
            </linearGradient>
          </defs>
        </svg>
      </Box>
      <Typography variant="h5" sx={{ color: "white" }}>
        {item.fname}
      </Typography>
      <Typography variant="h5" sx={{ color: "white" }}>
        {item.lname}
      </Typography>
      {item.title}
      <Box mt={1}>
        {item.linkedin && (
          <IconButton
            edge={direction === "left" ? "start" : "end"}
            onClick={() => {
              window.open(item.linkedin, "_blank");
            }}>
            <LinkedIn sx={{ color: "white" }} />
          </IconButton>
        )}
        {item.twitter && (
          <IconButton
            onClick={() => {
              window.open(item.twitter, "_blank");
            }}>
            <Twitter sx={{ color: "white" }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );

  var row = 0;
  return (
    <Container>
      <Typography
        width={"100%"}
        textAlign={"center"}
        className={classes.gradientText}
        sx={{ typography: { md: "h2", xs: "h3" } }}>
        OUR TEAM
      </Typography>
      <Box mb={{ md: 15, xs: 6 }} />
      <Grid container spacing={{ xs: 4, md: 8 }}>
        {mock.map((item, i) => {
          if (isSM) {
            row += 1;
          } else {
            if (i % 2 === 0) {
              row += 1;
            }
          }
          return (
            <Grid item xs={12} md={6} key={i}>
              {/* <Box>
                <Grid container sx={{ width: "100%", height: "100%" }}>
                  {row % 2 !== 0 ? (
                    <>
                      <Grid item xs={6} md={6}>
                        {personImage(item)}
                      </Grid>
                      <Grid item xs={6} md={6}>
                        {role(item, "left")}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={6} md={6}>
                        {role(item, "right")}
                      </Grid>
                      <Grid item xs={6} md={6}>
                        {personImage(item)}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box> */}
              {/* <Box display={"flex"} height={"100%"} justifyContent={"center"}>
                {row % 2 !== 0 ? (
                  <>
                    {personImage(item)}
                    {role(item, "left")}
                  </>
                ) : (
                  <>
                    {role(item, "right")}
                    {personImage(item)}
                  </>
                )}
              </Box> */}
              <Grid container>
                {row % 2 !== 0 ? (
                  <>
                    <Grid item xs={6} md={6}>
                      {personImage(item)}
                    </Grid>
                    <Grid item xs={6} md={6}>
                      {role(item, "left")}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6} md={6}>
                      {role(item, "right")}
                    </Grid>
                    <Grid item xs={6} md={6}>
                      {personImage(item)}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

export default Team;
