import React from "react";
import { Dialog } from "@mui/material";

function VideoPlayerDialog({ onClose }) {
  const src =
    "https://res.cloudinary.com/ravenapp/video/upload/v1716392321/ai_copilot/Raven_AI_Product_Demo_V4_160524_usqnru.mp4";
  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{ padding: 0, margin: 0 }}
      PaperProps={{ style: { padding: 0 } }}>
      <video controls width="100%" autoPlay>
        <source src={src} type="video/mp4" />
      </video>
    </Dialog>
  );
}

export default VideoPlayerDialog;
