import React from "react";
import Container from "../../../components/Container";
import { Box, Typography, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  icon: {
    width: 72,
    height: 72,
    [theme.breakpoints.down("sm")]: {
      width: 48,
      height: 48,
    },
  },
  dropImage: {
    width: 154,
    [theme.breakpoints.down("sm")]: {
      width: 80,
    },
  },
}));

function ProblemAndSolution({ problem, solution }) {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <Container
      wrapperStyle={{ background: theme.palette.background.light }}
      containerStyle={{ alignItems: "center" }}>
      <Box maxWidth={720} borderRadius={8}>
        <Box
          px={{ xs: 3, md: 5 }}
          py={{ xs: 4, md: 6 }}
          bgcolor={"#fff"}
          sx={{ borderTopLeftRadius: 32, borderTopRightRadius: 32 }}>
          <Box display={"flex"} alignItems={"center"}>
            <img src="/report.svg" alt="report" className={classes.icon} />
            <Box mr={2} />
            <Typography
              sx={{
                typography: { xs: "body1", md: "h6" },
                fontWeight: "500 !important",
              }}>
              PROBLEM
            </Typography>
          </Box>
          <Box mb={{ xs: 3, md: 4 }} />
          <Typography sx={{ typography: { xs: "h6", md: "h5" } }}>
            {problem}
          </Typography>
        </Box>
        <Box
          position={"relative"}
          px={{ xs: 3, md: 5 }}
          pb={{ xs: 4, md: 6 }}
          pt={{ xs: 4, md: 9 }}
          sx={{
            borderBottomLeftRadius: 32,
            borderBottomRightRadius: 32,
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%)",
          }}>
          <Box display={"flex"} alignItems={"center"}>
            <img src="/verified.svg" alt="verified" className={classes.icon} />
            <Box mr={2} />
            <Typography
              sx={{
                color: "white",
                typography: { xs: "subtitle1", md: "h6" },
                fontWeight: "500 !important",
              }}>
              SOLUTION
            </Typography>
          </Box>
          <Box mb={{ xs: 3, md: 4 }} />
          <Typography
            variant="h4"
            color={"textSecondary"}
            sx={{ color: "white", typography: { xs: "h6", md: "h5" } }}>
            {solution}
          </Typography>
          <Box
            sx={{
              position: "absolute",
              top: -10,
              left: "50%",
              transform: "translateX(-50%)",
            }}>
            <Box position={"relative"}>
              <img src="/Drop-Shape.png" className={classes.dropImage} />
              <Typography
                fontSize={{ md: 29, xs: 20 }}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  color:
                    "linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%)",
                }}>
                ✦
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default ProblemAndSolution;
