import React, { useEffect } from "react";
import Hero from "../components/Hero";
import { Box, useTheme } from "@mui/material";
import ProblemAndSolution from "../components/ProblemAndSolution";
import CTA from "../../Home/CTA";
import { makeStyles } from "tss-react/mui";
import { useFormProvider } from "../../../providers/FormProvider";
import FeaturesCenter from "./FeaturesCenter";
import {
  AnalyticsOutlined,
  IntegrationInstructionsOutlined,
  PolicyOutlined,
  StorageRounded,
} from "@mui/icons-material";
import { usePostHog } from "posthog-js/react";
import {
  CLICK_SCHEDULE_DEMO_BTN,
  VIEW_OPERATIONS_AND_MAINTAINANCE_ASSISTANT_PAGE,
} from "../../../components/posthogEvents";

const features = [
  {
    key: 0,
    title: "Rapid Data Retrieval",
    description:
      "Query data from multiple sources like databases, excel, docs, images & more.",
    image: "/operations_and_maintainance/data_aggregation.png",
    icon: <StorageRounded sx={{ color: "white" }} />,
  },
  {
    key: 1,
    title: "Speedy RCA preparation",
    description:
      "Perform data analysis, quick RCA preparation and enable smart decision making",
    image: "/operations_and_maintainance/smart_analytics.png",
    icon: <AnalyticsOutlined sx={{ color: "white" }} />,
  },
  {
    key: 2,
    title: "Health, Safety, and Environment (HSE) Enhancement",
    description:
      "On demand access to safety procedures, reports and policies for your employees",
    image: "/operations_and_maintainance/safety_hub.png",
    icon: <PolicyOutlined sx={{ color: "white" }} />,
  },
  {
    key: 3,
    title: "Dynamic data",
    description:
      "Integration of dynamic process parameters and enterprise tools like SAP, Historian etc.",
    image: "/operations_and_maintainance/process_integration.png",
    icon: <IntegrationInstructionsOutlined sx={{ color: "white" }} />,
  },
];

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background:
      "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function OperationsAndMaintenanceAssistant() {
  const theme = useTheme();
  const { classes } = useStyles();
  const posthog = usePostHog();
  const { handleOpenForm } = useFormProvider();

  const handleCTAClick = () => {
    handleOpenForm();
    posthog.capture(CLICK_SCHEDULE_DEMO_BTN);
  };

  useEffect(() => {
    posthog.capture(VIEW_OPERATIONS_AND_MAINTAINANCE_ASSISTANT_PAGE);
  }, []);

  return (
    <Box sx={{ bgcolor: theme.palette.background.dark, overflowX: "hidden" }}>
      <Hero
        title={
          <>
            AI Assistant for{" "}
            <span className={classes.gradientText}>
              Operations & Maintenance teams
            </span>
          </>
        }
        subtitle={
          "O&M teams can rely on Raven's AI assistant for quick questions and analysis which saves time and streamlines workflows."
        }
        img={"/operations_and_maintainance/hero_illustration"}
        onClick={handleCTAClick}
      />
      <ProblemAndSolution
        problem={
          "Data siloes hinder information access and slows down decision making when troubleshooting issues."
        }
        solution={
          "Our software provides quick access to necessary data, supporting rapid decision-making and speeding up workflows."
        }
        onClick={handleCTAClick}
      />
      <FeaturesCenter features={features} />
      <CTA title={"Curious to know more?"} onClick={handleCTAClick} />
    </Box>
  );
}

export default OperationsAndMaintenanceAssistant;
