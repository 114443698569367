import React from "react";
import Container from "../../../components/Container";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Button,
  useMediaQuery,
} from "@mui/material";
import {
  Api,
  ArrowOutward,
  CloudDone,
  PhoneIphone,
  QuestionAnswer,
  SettingsVoice,
  WhatsApp,
} from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import GradientIcon from "../../../components/GradientIcon";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background:
      "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  plan: {
    borderRadius: 32,
    border: "1px solid rgba(255, 255, 255, 0.12)",
    background: "rgba(255, 255, 255, 0.02)",
    boxShadow: "2px 2px 10px 0px rgba(169, 61, 253, 0.16)",
    padding: 32,
    margin: "0 auto",
  },
}));

const usecases = [
  { key: 0, label: "Raven Cloud", icon: <GradientIcon Icon={CloudDone} /> },
  {
    key: 1,
    label: "2-Way Voice Conversation",
    icon: <GradientIcon Icon={SettingsVoice} />,
  },
  { key: 2, label: "WhatsApp Chat", icon: <GradientIcon Icon={WhatsApp} /> },
  { key: 3, label: "Mobile", icon: <GradientIcon Icon={PhoneIphone} /> },
  {
    key: 4,
    label: "Chatbot on your Website",
    icon: <GradientIcon Icon={QuestionAnswer} />,
  },
  {
    key: 5,
    label: "APIs to integrate in your workflow",
    icon: <GradientIcon Icon={Api} />,
  },
];

function WhyRaven({ benefits }) {
  const theme = useTheme();
  const { classes } = useStyles();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container wrapperStyle={{ background: theme.palette.background.light }}>
      <Typography
        sx={{
          width: "100%",
          color: "#F1F1F1",
          textAlign: "center",
          typography: { md: "h2", xs: "h3" },
        }}
        component={"h2"}>
        WHY RAVEN
      </Typography>
      <Box mb={6} />
      <Box className={isXS ? null : classes.plan}>
        <Typography
          className={classes.gradientText}
          sx={{ typography: { md: "h4", xs: "h5" } }}
          textAlign={"center"}>
          Access based on your usecase
        </Typography>
        <Box mb={4} />
        <Box display={"flex"} flexWrap={"wrap"} rowGap={2}>
          {usecases.map((usecase, i) => {
            const { key, label, icon } = usecase;
            return (
              <Box
                key={key}
                // p={1}
                pr={1.5}
                display={"flex"}
                alignItems={"center"}
                borderRadius={12.5}
                mr={2}
                sx={{ border: "1px solid #333638", background: "#212426" }}>
                <IconButton
                  sx={{
                    background:
                      "linear-gradient(140deg, rgba(255, 255, 255, 0.08) 14.9%, rgba(255, 255, 255, 0.08) 50.44%, rgba(33, 36, 38, 0.08) 71.41%)",
                  }}>
                  {icon}
                </IconButton>
                <Box mr={2} />
                <Typography
                  sx={{
                    color: "#F1F1F1",
                    typography: { md: "h6", xs: "body1" },
                  }}>
                  {label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box mb={6} />
      <Box className={isXS ? null : classes.plan}>
        <Typography
          sx={{ typography: { md: "h4", xs: "h5" } }}
          className={classes.gradientText}
          textAlign={"center"}>
          How is it better than others?
        </Typography>
        <Box mb={4} />
        {benefits.map((benefit, i) => {
          const { key, icon, label } = benefit;
          return (
            <Box
              key={key}
              display={"flex"}
              alignItems={"center"}
              mb={i === benefits.length - 1 ? 0 : 3}>
              <IconButton
                sx={{
                  background:
                    "linear-gradient(140deg, rgba(255, 255, 255, 0.08) 14.9%, rgba(255, 255, 255, 0.08) 50.44%, rgba(33, 36, 38, 0.08) 71.41%)",
                }}>
                {icon}
              </IconButton>
              <Box mr={2} />
              <Typography
                sx={{
                  color: "#F1F1F1",
                  typography: { md: "h6", xs: "body1" },
                }}>
                {label}
              </Typography>
            </Box>
          );
        })}
      </Box>
      {/* <Button
        variant="outlined"
        size="large"
        fullWidth={isXS}
        endIcon={<ArrowOutward color="#BF40AE" />}
        sx={{
          borderColor: "#BF40AE",
          background: theme.palette.background.light,
        }}
      >
        <span className={classes.gradientText}>Read why choose Raven</span>
      </Button> */}
    </Container>
  );
}

export default WhyRaven;
