import React from "react";
import { Dialog } from "@mui/material";

function JotFormEmbed({ handleClose }) {
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={true}
        onClose={handleClose}
        sx={{ padding: 0, margin: 0 }}>
        <iframe
          id="JotFormIFrame-241271985579067"
          title="Raven AI Copilot User Registration"
          onload="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allow="geolocation; microphone; camera; fullscreen"
          src="https://form.jotform.com/241271985579067"
          frameborder="0"
          style={{
            minWidth: "100%",
            maxWidth: "100%",
            height: 539,
            border: "none",
          }}
          scrolling="no"></iframe>
      </Dialog>
    </>
  );
}

export default JotFormEmbed;
