import React, { useEffect } from "react";
import Hero from "../components/Hero";
import { Box, SvgIcon, useTheme } from "@mui/material";
import CTA from "../../Home/CTA";
import { makeStyles } from "tss-react/mui";
import FeaturesCenter from "../components/FeaturesCenter";
import {
  ShortcutRounded,
  SatelliteOutlined,
  ContactSupportOutlined,
  AutoAwesome,
  Mediation,
  MoveDown,
  VerifiedUser,
} from "@mui/icons-material";
import WhyRaven from "../components/WhyRaven";
import Pricing from "../components/Pricing";
import Usecase from "../components/Usecase";
import { usePostHog } from "posthog-js/react";
// import {
//   AutoAwesome,
//   Mediation,
//   MoveDown,
//   Moving,
//   VerifiedUser,
// } from "../../../components/GradientIcons";
import GradientIcon from "../../../components/GradientIcon";
import {
  CLICK_START_FOR_FREE_BTN,
  VIEW_MANUALS_AND_CODE_SEARCH_PAGE,
} from "../../../components/posthogEvents";

const features = [
  {
    key: 0,
    title: "Ask Manuals",
    description: "Query in natural language",
    image: "/manuals_and_code_search/ask_manual.png",
    icon: <ContactSupportOutlined sx={{ color: "white" }} />,
  },
  {
    key: 1,
    title: "Follow-Up QnA",
    description: "Ask follow-ups",
    image: "/manuals_and_code_search/followup.png",
    icon: (
      <ShortcutRounded
        sx={{ color: "white", transform: "scaleX(-1) rotate(180deg)" }}
      />
    ),
  },
  {
    key: 2,
    title: "Ask Images",
    description: "Query images like diagrams & schematics ",
    image: "/manuals_and_code_search/ask_image.png",
    icon: <SatelliteOutlined sx={{ color: "white" }} />,
  },
];

const benefits = [
  // {
  //   key: 0,
  //   label: "AI engineered to interpret technical data like in manuals",
  //   icon: <AutoAwesome />,
  // },
  {
    key: 1,
    label:
      "20% better accuracy than ChatGPT and Microsoft Copilot. AI fine-tuned to interpret technical data from manuals ",
    icon: <GradientIcon Icon={AutoAwesome} />,
  },
  {
    key: 2,
    label:
      "Multimodal (answers on images like wiring diagrams, PIDs, flowcharts)",
    icon: <GradientIcon Icon={Mediation} />,
  },
  {
    key: 3,
    label: "Ask follow-ups",
    icon: <GradientIcon Icon={MoveDown} />,
  },
  {
    key: 4,
    label: "Your data is secure. SOC2 compliant",
    icon: <GradientIcon Icon={VerifiedUser} />,
  },
];

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background:
      "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function ManualsAndCodeSearch() {
  const posthog = usePostHog();
  const theme = useTheme();
  const { classes } = useStyles();

  const handleCTAClick = () => {
    window.open("https://copilot.startraven.com", "_blank");
    posthog.capture(CLICK_START_FOR_FREE_BTN);
  };

  useEffect(() => {
    posthog.capture(VIEW_MANUALS_AND_CODE_SEARCH_PAGE);
  }, []);

  return (
    <Box sx={{ bgcolor: theme.palette.background.dark, overflowX: "hidden" }}>
      <Hero
        title={
          <>
            Chat with your <br />
            <span className={classes.gradientText}>Technical Data</span>
          </>
        }
        subtitle={
          "Raven helps manufacturers with a QnA assistant on their technical data like manuals & images."
        }
        img={"/manuals_and_code_search/hero"}
        btnText="Start for free"
        onClick={handleCTAClick}
        isDemo={true}
      />
      <Usecase />
      <FeaturesCenter features={features} />
      <WhyRaven benefits={benefits} />
      <Pricing />
      <CTA
        title={"Start with your usecase"}
        btnText="Start for free"
        onClick={handleCTAClick}
      />
    </Box>
  );
}

export default ManualsAndCodeSearch;
