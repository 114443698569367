import React from "react";
import Container from "../../../components/Container";
import {
  Typography,
  Grid,
  useTheme,
  Box,
  Chip,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ExpandMore, UploadFile } from "@mui/icons-material";
import Xarrow, { Xwrapper } from "react-xarrows";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background:
      "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

const usecases = [
  {
    key: 0,
    title: "Reduce Tech Support Cost",
    description:
      "Reduce tech support costs using an AI agent that helps customers troubleshoot issues quickly.",
    image: "/manuals_and_code_search/tech_support.png",
  },
  {
    key: 1,
    title: "Close Sales Deals Faster",
    description:
      "Reduce lead times by equipping your sales team with an AI that assists with technical questions by customers.",
    image: "/manuals_and_code_search/sales_deals.png",
  },
  {
    key: 2,
    title: "Quicker Installation & Servicing",
    description:
      "Field technicians can fix issues faster during installation or servicing.",
    image: "/manuals_and_code_search/installation_servicing.png",
  },
  {
    key: 3,
    title: "Maintenance & Inspection Efficiency",
    description:
      "Maintenance & Inspection teams can query manuals, SOPs, regulations/codes.",
    image: "/manuals_and_code_search/maintenance_inspection.png",
  },
];

function Usecase() {
  const { classes } = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container wrapperStyle={{ background: theme.palette.background.light }}>
      <Typography
        textAlign={"center"}
        sx={{ color: "#F1F1F1", typography: { md: "h2", xs: "h3" } }}>
        Built for <br />{" "}
        <span className={classes.gradientText}>
          manufacturers, OEMs and engineers
        </span>
      </Typography>
      <Box mb={{ md: 11, xs: 4 }} />
      <Xwrapper>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          sx={{
            background: {
              xs: "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
              md: "transparent",
            },
            borderTopLeftRadius: { xs: 32, md: 0 },
            borderTopRightRadius: { xs: 32, md: 0 },
          }}>
          <Box
            id="parent"
            component={"div"}
            borderRadius={8}
            p={2}
            bgcolor={"#FAFAFA"}
            display={"flex"}
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={"center"}
            margin="0 auto">
            <Box
              borderRadius={4}
              p={1}
              mr={{ xs: 0, sm: 2 }}
              mb={{ xs: 2, sm: 0 }}
              display={"flex"}
              sx={{
                background:
                  "linear-gradient(292deg, rgba(115, 38, 104, 0.80) 0.1%, rgba(64, 38, 115, 0.80) 50.05%, rgba(47, 38, 115, 0.80) 100%)",
                boxShadow: "4px 8px 20px 0px rgba(1, 16, 98, 0.40)",
                backdropFilter: "blur(10px)",
              }}>
              <UploadFile sx={{ color: "white" }} />
            </Box>
            <Typography variant="h5" textAlign={{ xs: "center", sm: "left" }}>
              Upload manuals, data-sheets, diagrams or guides
            </Typography>
            <Box mb={{ xs: 2, sm: 0 }} />
          </Box>
        </Box>
        <Box
          width={"100%"}
          sx={{
            background: {
              xs: "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
              md: "transparent",
            },
          }}>
          <Box mb={{ xs: -2, sm: 2 }} />
          <Box display={"flex"} justifyContent={"center"}>
            <Chip
              variant="outlined"
              label="Then use Raven AI for"
              onDelete={() => {}}
              deleteIcon={
                <ExpandMore
                  fontSize="small"
                  sx={{ color: "white !important" }}
                />
              }
              sx={{
                background: theme.palette.background.light,
                borderColor: "#C040AE",
                color: "white",
                zIndex: 2,
                cursor: "default",
              }}
            />
          </Box>
          <Box mt={{ xs: 2, sm: 20 }} />
        </Box>
        <Grid
          container
          sx={{
            background: {
              xs: "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
              md: "transparent",
            },
            borderBottomLeftRadius: { xs: 24, md: 0 },
            borderBottomRightRadius: { xs: 24, md: 0 },
          }}
          rowGap={{ xs: 3, md: 0 }}
          columnSpacing={{ xs: 0, md: 3 }}
          px={{ md: 0, xs: 1 }}
          pb={{ md: 0, xs: 1 }}>
          {usecases.map((usecase, i) => {
            const { key, title, description, image } = usecase;
            return (
              <Grid item key={key} md={3} xs={12}>
                <Box
                  width={"100%"}
                  height={"100%"}
                  component={"div"}
                  id={`feature${i + 1}`}
                  key={key}
                  display={"flex"}
                  flexDirection={"column"}
                  flex={1}
                  borderRadius={{ xs: 6, md: 8 }}
                  sx={{
                    border: "1px solid #8EBFF0",
                    borderWidth: { xs: 0, md: 1 },
                  }}>
                  <img
                    src={image}
                    alt={title}
                    width={"100%"}
                    style={{
                      borderTopLeftRadius: isXS ? 24 : 32,
                      borderTopRightRadius: isXS ? 24 : 32,
                    }}
                  />
                  <Box
                    height={"100%"}
                    bgcolor={{
                      md: "#1E2022",
                      xs: theme.palette.background.light,
                    }}
                    p={{ xs: 2, sm: 3 }}
                    sx={{
                      borderBottomLeftRadius: { md: 32, xs: 24 },
                      borderBottomRightRadius: { md: 32, xs: 24 },
                    }}>
                    <Typography variant="h5" sx={{ color: "#F1F1F1" }}>
                      {title}
                    </Typography>
                    <Box mb={2} />
                    <Typography
                      fontWeight={500}
                      sx={{ color: "rgba(241, 241, 241, 0.87)" }}>
                      {description}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {/* <Box
          display={"flex"}
          flexDirection={{ xs: "column", sm: "row" }}
          width={"100%"}>
          {usecases.map((usecase, i) => {
            const { key, title, description, image } = usecase;
            return (
              <Box
                component={"div"}
                id={`feature${i + 1}`}
                key={key}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                borderRadius={8}
                sx={{ border: "1px solid #8EBFF0" }}
                mb={{ xs: i === usecases.length - 1 ? 0 : 3, sm: 0 }}
                mr={{ xs: 0, sm: i === usecases.length - 1 ? 0 : 4 }}>
                <img
                  src={image}
                  alt={title}
                  width={"100%"}
                  style={{
                    borderTopLeftRadius: 32,
                    borderTopRightRadius: 32,
                  }}
                />
                <Box
                  bgcolor={"#1E2022"}
                  p={{ xs: 2, sm: 3 }}
                  sx={{
                    borderBottomLeftRadius: 32,
                    borderBottomRightRadius: 32,
                  }}>
                  <Typography variant="h5" sx={{ color: "#F1F1F1" }}>
                    {title}
                  </Typography>
                  <Box mb={2} />
                  <Typography
                    fontWeight={500}
                    sx={{ color: "rgba(241, 241, 241, 0.87)" }}>
                    {description}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box> */}
        {!isXS &&
          usecases.map((_, i) => {
            return (
              <Xarrow
                key={i}
                start={"parent"}
                end={`feature${i + 1}`}
                startAnchor={"bottom"}
                endAnchor={"top"}
                strokeWidth={12}
                showHead={false}
                color="#988FE8"
                curveness={1}
              />
            );
          })}
      </Xwrapper>
    </Container>
  );
}

function Usecase1() {
  const { classes } = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container wrapperStyle={{ background: theme.palette.background.light }}>
      <Typography
        textAlign={"center"}
        sx={{ color: "#F1F1F1", typography: { md: "h2", xs: "h3" } }}>
        Built for <br />{" "}
        <span className={classes.gradientText}>
          manufacturers, OEMs and engineers
        </span>
      </Typography>
      <Box mb={{ md: 11, xs: 4 }} />
      <Box
        sx={{
          background: {
            xs: "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
            md: "transparent",
          },
          borderTopLeftRadius: { xs: 32, md: 0 },
          borderTopRightRadius: { xs: 32, md: 0 },
          borderBottomLeftRadius: { xs: 24, md: 0 },
          borderBottomRightRadius: { xs: 24, md: 0 },
        }}>
        <Xwrapper>
          <Box
            id="parent"
            component={"div"}
            display={"flex"}
            justifyContent={"center"}>
            <Box
              borderRadius={8}
              p={2}
              bgcolor={"#FAFAFA"}
              display={"flex"}
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={"center"}
              margin="0 auto">
              <Box
                borderRadius={4}
                p={1}
                mr={{ xs: 0, sm: 2 }}
                mb={{ xs: 2, sm: 0 }}
                display={"flex"}
                sx={{
                  background:
                    "linear-gradient(292deg, rgba(115, 38, 104, 0.80) 0.1%, rgba(64, 38, 115, 0.80) 50.05%, rgba(47, 38, 115, 0.80) 100%)",
                  boxShadow: "4px 8px 20px 0px rgba(1, 16, 98, 0.40)",
                  backdropFilter: "blur(10px)",
                }}>
                <UploadFile sx={{ color: "white" }} />
              </Box>
              <Typography variant="h5" textAlign={{ xs: "center", sm: "left" }}>
                Upload manuals, data-sheets, diagrams or guides
              </Typography>
              <Box mb={{ xs: 2, sm: 0 }} />
            </Box>
          </Box>
          <Box mb={{ xs: -2, sm: 2 }} />
          <Box display={"flex"} justifyContent={"center"}>
            <Chip
              variant="outlined"
              label="Then use Raven AI for"
              onDelete={() => {}}
              deleteIcon={
                <ExpandMore
                  fontSize="small"
                  sx={{ color: "white !important" }}
                />
              }
              sx={{
                background: theme.palette.background.light,
                borderColor: "#C040AE",
                color: "white",
                zIndex: 2,
                cursor: "default",
              }}
            />
          </Box>
          <Grid
            container
            spacing={3}
            mt={{ xs: 2, sm: 20 }}
            px={{ md: 0, xs: 1 }}
            pb={{ md: 0, xs: 1 }}>
            {usecases.map((usecase, i) => {
              const { key, title, description, image } = usecase;
              return (
                <Grid item key={key} md={3} xs={12}>
                  <Box
                    width={"100%"}
                    height={"100%"}
                    component={"div"}
                    id={`feature${i + 1}`}
                    key={key}
                    display={"flex"}
                    flexDirection={"column"}
                    flex={1}
                    borderRadius={{ xs: 6, md: 8 }}
                    sx={{
                      border: "1px solid #8EBFF0",
                      borderWidth: { xs: 0, md: 1 },
                    }}>
                    <img
                      src={image}
                      alt={title}
                      width={"100%"}
                      style={{
                        borderTopLeftRadius: isXS ? 24 : 32,
                        borderTopRightRadius: isXS ? 24 : 32,
                      }}
                    />
                    <Box
                      height={"100%"}
                      bgcolor={{
                        md: "#1E2022",
                        xs: theme.palette.background.light,
                      }}
                      p={{ xs: 2, sm: 3 }}
                      sx={{
                        borderBottomLeftRadius: { md: 32, xs: 24 },
                        borderBottomRightRadius: { md: 32, xs: 24 },
                      }}>
                      <Typography variant="h5" sx={{ color: "#F1F1F1" }}>
                        {title}
                      </Typography>
                      <Box mb={2} />
                      <Typography
                        fontWeight={500}
                        sx={{ color: "rgba(241, 241, 241, 0.87)" }}>
                        {description}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {!isXS &&
            usecases.map((_, i) => {
              return (
                <Xarrow
                  key={i}
                  start={"parent"}
                  end={`feature${i + 1}`}
                  startAnchor={"bottom"}
                  endAnchor={"top"}
                  strokeWidth={12}
                  showHead={false}
                  color="#988FE8"
                  background="var(--Color-Styles-Raven-AI---Light, linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%))"
                  curveness={1}
                />
              );
            })}
          {/* <Box
          display={"flex"}
          flexDirection={{ xs: "column", sm: "row" }}
          width={"100%"}>
          {usecases.map((usecase, i) => {
            const { key, title, description, image } = usecase;
            return (
              <Box
                component={"div"}
                id={`feature${i + 1}`}
                key={key}
                display={"flex"}
                flexDirection={"column"}
                flex={1}
                borderRadius={8}
                sx={{ border: "1px solid #8EBFF0" }}
                mb={{ xs: i === usecases.length - 1 ? 0 : 3, sm: 0 }}
                mr={{ xs: 0, sm: i === usecases.length - 1 ? 0 : 4 }}>
                <img
                  src={image}
                  alt={title}
                  width={"100%"}
                  style={{
                    borderTopLeftRadius: 32,
                    borderTopRightRadius: 32,
                  }}
                />
                <Box
                  bgcolor={"#1E2022"}
                  p={{ xs: 2, sm: 3 }}
                  sx={{
                    borderBottomLeftRadius: 32,
                    borderBottomRightRadius: 32,
                  }}>
                  <Typography variant="h5" sx={{ color: "#F1F1F1" }}>
                    {title}
                  </Typography>
                  <Box mb={2} />
                  <Typography
                    fontWeight={500}
                    sx={{ color: "rgba(241, 241, 241, 0.87)" }}>
                    {description}
                  </Typography>
                </Box>
              </Box>
            );
          })}
          {!isXS &&
            usecases.map((_, i) => {
              return (
                <Xarrow
                  key={i}
                  start={"parent"}
                  end={`feature${i + 1}`}
                  startAnchor={"bottom"}
                  endAnchor={"top"}
                  strokeWidth={12}
                  showHead={false}
                  color="#988FE8"
                  background="var(--Color-Styles-Raven-AI---Light, linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%))"
                  curveness={1}
                />
              );
            })}
        </Box> */}
        </Xwrapper>
      </Box>
    </Container>
  );
}

export default Usecase;
