import React from "react";
import Container from "../../components/Container";
import { Typography, Box, Button } from "@mui/material";
import GradientBorderButtonWrapper from "../../components/GradientBorderButtonWrapper";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background:
      "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function Hero({ handleOpenForm }) {
  const { classes } = useStyles();
  return (
    <Container
      wrapperStyle={{ marginTop: 78.5 }}
      containerStyle={{ width: "100%", alignItems: "center" }}>
      <Typography
        textAlign={"center"}
        sx={{ color: "white", typography: { xs: "h3", md: "h1" } }}>
        <span className={classes.gradientText}>
          Revolutionizing <br /> Industry Operations <br />
        </span>{" "}
        with Raven's AI
      </Typography>
      <Box mb={{ xs: 4, md: 6 }} />
      <Box width={{ xs: "100%", md: "initial" }}>
        <GradientBorderButtonWrapper>
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={handleOpenForm}>
            SCHEDULE A DEMO
          </Button>
        </GradientBorderButtonWrapper>
      </Box>
      <Box mb={6} />
      <img
        src="/hero_illustration_raven_ai.png"
        width={"100%"}
        style={{ maxWidth: 720 }}
      />
    </Container>
  );
}

export default Hero;
