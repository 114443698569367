import React from "react";
import Hero from "../IntelligentAISearch/components/Hero";
import { Box, useTheme } from "@mui/material";
import ProblemAndSolution from "../IntelligentAISearch/components/ProblemAndSolution";
import Features from "../IntelligentAISearch/components/Features";
import CTA from "../Home/CTA";
import { useFormProvider } from "../../providers/FormProvider";

const features = [
  {
    key: 0,
    title: "Voice-Driven Compliance",
    description:
      "Enforces strict adherence to protocols through direct voice instructions, ensuring safety and regulatory compliance.",
    image: "/voice_inspection/feature_1.png",
  },
  {
    key: 1,
    title: "Automated Documentation",
    description:
      "Streamlines the inspection process by automatically recording findings and generating reports.",
    image: "/voice_inspection/feature_2.png",
  },
  {
    key: 2,
    title: "Enhanced Operational Throughput",
    description:
      "Boosts efficiency by reducing manual entry and expediting the inspection process.",
    image: "/voice_inspection/feature_3.png",
  },
];
function VoiceInspectionGuidance() {
  const theme = useTheme();
  const { handleOpenForm } = useFormProvider();

  return (
    <Box sx={{ bgcolor: theme.palette.background.dark, overflowX: "hidden" }}>
      <Hero
        title={"Voice Inspection Guidance"}
        subtitle={
          "Ensure consistent and compliant inspections through direct voice instructions."
        }
        img={"/voice_inspection/hero_illustration"}
        onClick={handleOpenForm}
      />
      <ProblemAndSolution
        problem={
          "Inconsistent inspection processes lead to compliance risks and operational inefficiencies."
        }
        solution={
          "Voice-driven instructions ensure consistent adherence to protocols, enhancing safety and compliance."
        }
      />
      <Features features={features} />
      <CTA
        title={"Transform Your Inspection Processes"}
        onClick={handleOpenForm}
      />
    </Box>
  );
}

export default VoiceInspectionGuidance;
