import React from "react";
import Container from "../../../components/Container";
import { Typography, Box, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function Features({ features }) {
  const { classes } = useStyles();
  return (
    <Container>
      <Typography className={classes.gradientText} sx={{ typography: { md: "h2", xs: "h3" } }}>
        FEATURES
      </Typography>
      <Box mb={{ md: 15, xs: 6 }} />
      {features.map((feature, i) => {
        const { key, title, description, image } = feature;
        return (
          <Grid
            key={key}
            container
            columnSpacing={3}
            rowSpacing={3}
            alignItems={"center"}
            mb={{
              md: i !== features.length - 1 ? 25 : 0,
              xs: i !== features.length - 1 ? 9 : 0,
            }}
          >
            <Grid item md={6}>
              {/* <Typography
                sx={{ color: "white", typography: { md: "h3", xs: "h5" } }}>
                {title}
              </Typography>
              <Box mb={{ md: 3, xs: 2 }} /> */}
              <Typography
                sx={{
                  lineHeight: "150% !important",
                  color: "white",
                  typography: { md: "h4", xs: "h5" },
                }}
              >
                {description}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <img src={image} alt="feature" width={"100%"} />
            </Grid>
          </Grid>
        );
      })}
    </Container>
  );
}

export default Features;
