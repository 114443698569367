import React from "react";
import Container from "../../../components/Container";
import { Box, Button, Typography, Chip, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CheckCircle, ChevronRight } from "@mui/icons-material";
import { useFormProvider } from "../../../providers/FormProvider";
import { usePostHog } from "posthog-js/react";
import {
  CLICK_CONTACT_US_BTN,
  CLICK_START_FOR_FREE_BTN,
} from "../../../components/posthogEvents";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background:
      "linear-gradient(296deg, #EF8FE2 2.96%, #B08FEF 51.48%, #9A8FEF 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  plan: {
    borderRadius: 32,
    border: "1px solid rgba(255, 255, 255, 0.12)",
    background: "#111315",
    boxShadow: "2px 2px 10px 0px rgba(169, 61, 253, 0.16)",
    padding: 24,
  },
  divider: {
    height: 1,
    width: "100%",
    background:
      "linear-gradient(90deg, #111315 0%, #F1F1F1 0.01%, #111315 100%)",
    marginTop: 16,
    marginBottom: 16,
  },
}));

function Pricing() {
  const { handleOpenForm } = useFormProvider();
  const posthog = usePostHog();

  const plans = [
    {
      key: 0,
      plan: "free",
      cost: 0,
      benefits: ["50 queries/month. $10/mo after that.", "Cloud access"],
      cta: (
        <Button
          onClick={() => {
            window.open("https://copilot.startraven.com", "_blank");
            posthog.capture(CLICK_START_FOR_FREE_BTN);
          }}
          size="large"
          variant="contained"
          fullWidth
          endIcon={<ChevronRight />}>
          Start for free
        </Button>
      ),
    },
    {
      key: 1,
      plan: "pro",
      cost: 30,
      benefits: [
        "No limit on queries",
        "Diagram/Image search",
        "API access",
        "Integrations",
        "All capabilities of Free plan",
      ],
      // tag: "Most Popular",
      cta: (
        // <Button
        //   size="large"
        //   variant="contained"
        //   fullWidth
        //   onClick={() => {
        //     handleOpenForm();
        //     posthog.capture(CLICK_CONTACT_US_BTN);
        //   }}>
        //   Contact Us
        // </Button>
        <Button
          onClick={() => {
            window.open("https://copilot.startraven.com", "_blank");
            posthog.capture(CLICK_START_FOR_FREE_BTN);
          }}
          size="large"
          variant="contained"
          fullWidth
          endIcon={<ChevronRight />}>
          Start for free
        </Button>
      ),
    },
    // {
    //   key: 2,
    //   plan: "enterprise",
    //   cost: 100,
    //   benefits: [
    //     "5000 queries/month",
    //     "Diagram/Image search",
    //     "Quick file processing",
    //     "All capabilities of Free plan",
    //   ],
    //   cta: (
    //     <Button size="large" variant="contained" fullWidth>
    //       Contact Us
    //     </Button>
    //   ),
    // },
  ];
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <Container wrapperStyle={{ background: theme.palette.background.dark }}>
      <Typography
        width={"100%"}
        textAlign={"center"}
        color={"whitesmoke"}
        sx={{ typography: { md: "h2", xs: "h3" } }}>
        Pricing
      </Typography>
      <Box mb={{ md: 10, xs: 6 }} />
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row" }}
        width={"100%"}>
        {plans.map((p, i) => {
          const { key, plan, cost, benefits, tag, cta } = p;
          return (
            <Box
              flex={1}
              display={"flex"}
              flexDirection={"column"}
              key={key}
              className={classes.plan}
              mb={{ xs: 3, sm: 0 }}
              mr={{ xs: 0, sm: i === plans.length - 1 ? 0 : 4 }}>
              <Box flex={1} overflow={"auto"}>
                {tag && (
                  <Chip
                    size="small"
                    label={tag}
                    sx={{
                      marginBottom: 2,
                      background:
                        "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
                    }}
                  />
                )}
                <Box mb={{ xs: 0, sm: tag ? 0 : 5 }} />
                <Typography variant="h3" sx={{ color: "#F1F1F1" }}>
                  {plan}
                </Typography>
                <Box className={classes.divider} />
                {plan === "pro" && (
                  <Typography sx={{ color: "#F1F1F1" }}>Starting at</Typography>
                )}
                <Typography variant="h4" sx={{ color: "#F1F1F1" }}>
                  ${cost}
                  <Typography variant="h6" display={"inline"}>
                    /month
                  </Typography>
                </Typography>

                <Box mb={4} mt={2}>
                  {benefits.map((benefit, j) => {
                    return (
                      <Box
                        key={j}
                        display={"flex"}
                        alignItems={"center"}
                        py={0.5}
                        mb={2}>
                        <CheckCircle
                          sx={{ color: theme.palette.success.light }}
                        />
                        <Box mr={1} />
                        <Typography fontWeight={500} sx={{ color: "#F1F1F1" }}>
                          {benefit}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              {cta}
            </Box>
          );
        })}
      </Box>
    </Container>
  );
}

export default Pricing;
