import React from "react";
import { Box } from "@mui/material";

function GradientBorderButtonWrapper({ children, style }) {
  return (
    <Box
      borderRadius={2}
      sx={{
        position: "relative",
        background: "linear-gradient(to right, #DFA0EB, purple)",
        padding: "1.25px",
        ...style,
      }}>
      {children}
    </Box>
  );
}

export default GradientBorderButtonWrapper;
