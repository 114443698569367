import { createTheme } from "@mui/material/styles";
import { colors, alpha } from "@mui/material";
import typography from "./typography";

// A custom theme for this app
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        contained: {
          background:
            "linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%)",
        },
      },
    },
  },
  typography: typography,
  palette: {
    background: {
      default: "#ffffff",
      dark: "#121417",
      light: "#1A1C1E",
      paper: colors.common.white,
    },
    primary: {
      main: "#3F51B5",
      light: "#C5CAE9",
      dark: "#303F9F",
      lightest: "#C5CAE9",
      darkest: "#455A64",
      lightBg: "#E8EAF6",
    },
    secondary: {
      light: "#8C9EFF",
      main: "#536DFE",
      dark: "#3D5AFE",
      lightest: "#F6FAFD",
    },
    success: {
      main: "#2E7D32",
    },
    divider: alpha("#000000", 0.12),
    icon: "linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%)",
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
    },
  },
});

export default theme;
