export const VIEW_MANUALS_AND_CODE_SEARCH_PAGE =
  "mw_view_manuals_and_code_search_page";
export const VIEW_OPERATIONS_AND_MAINTAINANCE_ASSISTANT_PAGE =
  "mw_view_operations_and_maintainance_assistant_page";
export const VIEW_ABOUT_US_PAGE = "mw_view_about_us_page";
export const VIEW_BLOG_PAGE = "mw_view_blog_page";
export const VIEW_FREE_TOOLS_PAGE = "mw_view_free_tools_page";
export const CLICK_START_FOR_FREE_BTN = "mw_click_start_for_free_btn";
export const CLICK_SCHEDULE_DEMO_BTN = "mw_click_schedule_demo_btn";
export const CLICK_WATCH_PRODUCT_DEMO_BTN = "mw_click_watch_product_demo_btn";
export const CLICK_CONTACT_US_BTN = "mw_click_contact_us_btn";
