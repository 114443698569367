import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import Layout from "./components/Layout/Layout";
import ScrollToTop from "./components/ScrollToTop";
import FormProvider from "./providers/FormProvider";

function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <FormProvider>
          <Layout>
            <ScrollToTop />
            <Routes />
          </Layout>
        </FormProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
