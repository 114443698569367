import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { ExpandMore, PlayCircle } from "@mui/icons-material";
import VideoPlayerDialog from "../../../components/VideoPlayerDialog";
import { usePostHog } from "posthog-js/react";
import { CLICK_WATCH_PRODUCT_DEMO_BTN } from "../../../components/posthogEvents";

function Demo() {
  const theme = useTheme();
  const posthog = usePostHog();

  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);

  const handleVideoDialogOpen = () => {
    setIsVideoDialogOpen(true);
    posthog.capture(CLICK_WATCH_PRODUCT_DEMO_BTN);
  };
  return (
    <Box
      // wrapperStyle={{ py: 0 }}
      // sx={{ pt: 15, pb: 0 }}
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      alignItems={"center"}>
      <Box
        width={"100%"}
        mb={2}
        mt={10}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ color: "white" }}>
        <Typography variant="h6">Watch product demo</Typography>
        <Box mr={1} />
        <ExpandMore className="animatedIcon" />
      </Box>
      <Box
        borderRadius={2}
        position={"relative"}
        sx={{ width: "100%", maxWidth: 720, margin: "0 auto" }}>
        <img
          src="https://res.cloudinary.com/ravenapp/video/upload/so_0/v1716392321/ai_copilot/Raven_AI_Product_Demo_V4_160524_usqnru.jpg"
          alt="demo_video"
          width={"100%"}
          style={{ borderRadius: 8 }}
        />
        <Box
          display={"flex"}
          position={"absolute"}
          sx={{
            cursor: "pointer",
            top: "50%",
            left: "50%",
            zIndex: 1,
            transform: "translate(-50%,-50%)",
          }}
          onClick={handleVideoDialogOpen}>
          <PlayCircle
            sx={{
              color: theme.palette.secondary.main,
              fontSize: { md: 80, xs: 50 },
            }}
          />
        </Box>
      </Box>
      {isVideoDialogOpen && (
        <VideoPlayerDialog onClose={() => setIsVideoDialogOpen(false)} />
      )}
    </Box>
  );
}

export default Demo;
