export default {
  fontFamily: "Inter",
  h1: {
    fontWeight: 700,
    fontSize: 88,
    letterSpacing: "1px",
    lineHeight: "88px",
    fontFamily: "wise sans",
  },
  h2: {
    fontWeight: 700,
    fontSize: 60,
    letterSpacing: "1px",
    lineHeight: "60px",
    fontFamily: "wise sans",
  },
  h3: {
    fontWeight: 700,
    fontSize: 48,
    letterSpacing: "0px",
    lineHeight: "48px",
    fontFamily: "wise sans",
  },
  h4: {
    fontWeight: 600,
    fontSize: 34,
    letterSpacing: "0px",
    lineHeight: "51px",
  },
  h5: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: "0.15px",
    lineHeight: "40px",
  },
  h6: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: "0.15px",
    lineHeight: "34px",
  },
  overline: {
    fontWeight: 500,
  },
};
