import React from "react";
import Container from "../../components/Container";
import { Typography, Box, Button, useTheme } from "@mui/material";
import GradientBorderButtonWrapper from "../../components/GradientBorderButtonWrapper";

function CTA({ title, btnText = "Schedule a Demo", onClick = () => {} }) {
  const theme = useTheme();
  return (
    <Container
      wrapperStyle={{
        background: theme.palette.background.light,
      }}
      containerStyle={{ alignItems: "center" }}>
      <Typography
        sx={{
          typography: { md: "h2", xs: "h3" },
          color: "#F1F1F1",
          width: "100%",
        }}
        textAlign={"center"}>
        {title}
      </Typography>
      <Box mb={{ xs: 3, md: 7 }} />
      <Box width={{ xs: "100%", md: "initial" }}>
        <GradientBorderButtonWrapper>
          <Button fullWidth variant="contained" onClick={onClick} size="large">
            {btnText}
          </Button>
        </GradientBorderButtonWrapper>
      </Box>
    </Container>
  );
}

export default CTA;
