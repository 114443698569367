import React from "react";
import Container from "../../../components/Container";
import { Typography, Box, Grid, Button, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Feature from "./Feature";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background:
      "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function FeaturesCenter({ features }) {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <Container wrapperStyle={{ background: theme.palette.background.dark }}>
      <Typography
        className={classes.gradientText}
        sx={{
          width: "100%",
          typography: { md: "h2", xs: "h3" },
          textAlign: "center",
        }}>
        FEATURES
      </Typography>
      <Box mb={{ xs: 6, md: 15 }} />

      {features.map((feature, i) => {
        return (
          <Feature
            key={i}
            feature={feature}
            isLast={i === features.length - 1}
          />
        );
      })}
    </Container>
  );
}

export default FeaturesCenter;
