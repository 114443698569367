import React, { useEffect } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useFormProvider } from "../../providers/FormProvider";
import JotFormEmbed from "../../components/JotFormEmbed";

function Layout({ children }) {
  const { open, handleCloseForm, handleOpenForm } = useFormProvider();
  const location = useLocation();
  const isFormOpen = new URLSearchParams(location.search).get("isFormOpen");

  useEffect(() => {
    if (isFormOpen === "true") {
      handleOpenForm();
    }
  }, [isFormOpen]);

  return (
    <Box>
      <Navbar />
      {children}
      {open && <JotFormEmbed handleClose={handleCloseForm} />}
      <Footer />
    </Box>
  );
}

export default Layout;
