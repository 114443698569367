import React from "react";
import Hero from "./components/Hero";
import { Box, useTheme } from "@mui/material";
import ProblemAndSolution from "./components/ProblemAndSolution";
import Features from "./components/Features";
import CTA from "../Home/CTA";
import { makeStyles } from "tss-react/mui";
import { useFormProvider } from "../../providers/FormProvider";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background:
      "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

const features = [
  {
    key: 0,
    title: "Reduced Technical Support Costs",
    description:
      "Reduces support costs by automating responses to common technical queries.",
    image: "/oem/feature_1.png",
  },
  {
    key: 1,
    title: "On-Demand Training Modules",
    description:
      "Provides on-demand training modules for enhanced learning and product familiarity.",
    image: "/oem/feature_2.png",
  },
  {
    key: 2,
    title: "Sales Support Chatbot",
    description:
      "Sales support chatbot assists with technical queries and guides product selection.",
    image: "/oem/feature_3.png",
  },
];

function OEM360Assist() {
  const { classes } = useStyles();
  const theme = useTheme();
  const { handleOpenForm } = useFormProvider();

  return (
    <Box sx={{ bgcolor: theme.palette.background.dark, overflowX: "hidden" }}>
      <Hero
        title={
          <>
            AI Copilot for{" "}
            <span className={classes.gradientText}>OEM 360 Assist</span>
          </>
        }
        subtitle={
          "Empower your OEM with comprehensive, on-demand support and training."
        }
        img={"/oem/hero_illustration"}
        onClick={handleOpenForm}
      />
      <ProblemAndSolution
        problem={
          "OEMs struggle with high technical support costs, inefficient training, and slow sales processes due to technical queries."
        }
        solution={
          "Provides automated technical support, accessible training, and assists sales teams in engaging customers effectively."
        }
      />
      <Features features={features} />
      <CTA
        title={"Revolutionize Your OEM Operations"}
        onClick={handleOpenForm}
      />
    </Box>
  );
}

export default OEM360Assist;
