import React, { useRef, useCallback } from "react";
import Container from "./Container";
import { Typography, Box, Card, IconButton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    textAlign: "center",
    background:
      "linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

const testimonials = [
  {
    key: 0,
    name: "Guntha Rohith",
    role: "Software Engineer",
    testimonial:
      "Raven is like having a supercomputer by my side. It assists, but I'm in control. It's taken my support game to the next level.",
  },

  {
    key: 1,
    name: "Suryansh Srivastava",
    role: "Software Engineer",
    testimonial:
      "Raven is like having a supercomputer by my side. It assists, but I'm in control. It's taken my support game to the next level.",
  },
  {
    key: 2,
    name: "Priyansh Srivastava",
    role: "Software Engineer",
    testimonial:
      "Raven is like having a supercomputer by my side. It assists, but I'm in control. It's taken my support game to the next level.",
  },
  {
    key: 3,
    name: "Sriyansh Srivastava",
    role: "Software Engineer",
    testimonial:
      "Raven is like having a supercomputer by my side. It assists, but I'm in control. It's taken my support game to the next level.",
  },
];

function Testimonial() {
  const { classes } = useStyles();
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <Box
      sx={{ background: "#EFEFF5" }}
      px={{ md: 20, xs: 2 }}
      py={{ md: 16.25, xs: 6 }}>
      <Box maxWidth={1120} width={"100%"} margin={"0 auto"}>
        <Typography
          className={classes.gradientText}
          sx={{ typography: { xs: "h3", md: "h2" } }}>
          See why customers choose Raven AI
        </Typography>
        <Box mb={{ xs: 4, md: 7 }} />
        <Swiper
          ref={sliderRef}
          slidesPerView={1}
          spaceBetween={30}
          effect={"fade"}
          loop={true}
          // autoplay={{
          //   delay: 2000,
          //   disableOnInteraction: false,
          // }}
          // navigation={true}
          // pagination={{
          //   clickable: true,
          // }}
          modules={[Autoplay, EffectFade, Navigation, Pagination]}>
          {testimonials.map((t) => {
            const { key, name, role, testimonial } = t;
            return (
              <SwiperSlide key={key}>
                <Box
                  component={Card}
                  py={{ xs: 4, md: 15 }}
                  px={{ xs: 3, md: 10 }}
                  pb={{ xs: 3, md: 7.5 }}
                  borderRadius={8}
                  boxShadow={"2px 2px 10px 0px rgba(169, 61, 253, 0.16)"}
                  bgcolor={"#fff"}>
                  <Typography
                    textAlign={"center"}
                    sx={{ typography: { xs: "h5", md: "h4" } }}>
                    "{testimonial}"
                  </Typography>
                  <Box mb={6} />
                  <Typography
                    textAlign={"center"}
                    sx={{ typography: { xs: "h6", md: "h5" } }}>
                    {name}
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    sx={{ typography: { xs: "body1", md: "h6" } }}
                    fontWeight={400}>
                    {role}
                  </Typography>
                  <Box mb={11} />
                </Box>
              </SwiperSlide>
            );
          })}
          <Box
            position={"absolute"}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ left: 0, bottom: { xs: 32, md: 60 }, zIndex: 1 }}>
            <IconButton onClick={handlePrev}>
              <ArrowBack sx={{ fontSize: 32 }} />
            </IconButton>
            <IconButton onClick={handleNext}>
              <ArrowForward sx={{ fontSize: 32 }} />
            </IconButton>
          </Box>
        </Swiper>
      </Box>
    </Box>
  );
}

export default Testimonial;
