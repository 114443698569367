import React from "react";
import Container from "../../components/Container";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background:
      "linear-gradient(292deg, #732668 0.1%, #402673 50.05%, #2F2673 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function OurStory() {
  const { classes } = useStyles();
  return (
    <Container wrapperStyle={{ background: "#EFEFF5" }}>
      <Typography
        // className={classes.gradientText}
        sx={{ typography: { md: "h2", xs: "h3" } }}>
        OUR STORY
      </Typography>
      <Box mb={{ md: 6, xs: 5 }} />
      <Typography
        sx={{ typography: { xs: "body1", md: "h6" } }}
        color={"textSecondary"}>
        As brothers and developers at heart, we founded Raven AI after building
        systems from scratch for hyper-growth startups like Apna, India's
        fastest Unicorn, and OkCredit (YC S18, backed by Tiger Global,
        Lightspeed).
        <br />
        Today, Raven AI is helping industrial and business operations with
        advanced AI. Our flagship products, the Unified Voice AI Platform and
        Intelligent QnA AI are helping businesses gain efficiency across
        production, sales, support & more. Based in San Francisco and Bangalore,
        we are committed to being your partners in digital transformation,
        leveraging our deep tech expertise to drive innovation and growth.
      </Typography>
    </Container>
  );
}

export default OurStory;
