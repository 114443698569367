import React from "react";
import Container from "../../components/Container";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gradientText: {
    background: "linear-gradient(291deg, #EF8FE2 1.52%, #B08FEF 43.76%, #9A8FEF 86.01%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

function Hero() {
  const { classes } = useStyles();
  return (
    <Container wrapperStyle={{ marginTop: 78.5 }}>
      <Typography
        className={classes.gradientText}
        textAlign={"center"}
        sx={{ color: "white", typography: { xs: "h3", md: "h1" } }}
      >
        Increasing efficiency with AI
      </Typography>
    </Container>
  );
}

export default Hero;
