import React from "react";
import { Routes as Switch, Route, Navigate } from "react-router-dom";
import Home from "./views/Home/Home";
import OperationsAndMaintanceAssistant from "./views/IntelligentAISearch/OperationsAndMaintenanceAssistant/OperationsAndMaintenanceAssistant";
import ManualsAndCodeSearch from "./views/IntelligentAISearch/ManualsAndCodeSearch/ManualsAndCodeSearch";
import OEM360Assist from "./views/IntelligentAISearch/OEM360Assist";
import VoiceGuidedFieldAssistant from "./views/UnifiedVoiceAI/VoiceGuidedFieldAssistant";
import VoiceInspectionGuidance from "./views/UnifiedVoiceAI/VoiceInspectionGuidance";
import About from "./views/About/About";

export const OPERATIONS_AND_MAINTENANCE_ASSISTANT_URL =
  "/operations-and-maintenance-assistant";
export const MANUALS_AND_CODE_SEARCH_URL = "/manuals-and-code-search";
export const OEM_360_ASSIST_URL = "/oem-360-assist";
export const VOICE_GUIDED_FIELD_ASSISTANT_URL = "/voice-guided-field-assistant";
export const VOICE_INSPECTION_GUIDANCE_URL = "/voice-inspection-guidance";
export const ABOUT_URL = "/about";

const routesConfig = [
  {
    path: "/",
    component: <Navigate to={MANUALS_AND_CODE_SEARCH_URL} />,
  },
  {
    path: ABOUT_URL,
    component: <About />,
  },
  {
    path: OPERATIONS_AND_MAINTENANCE_ASSISTANT_URL,
    component: <OperationsAndMaintanceAssistant />,
  },
  {
    path: MANUALS_AND_CODE_SEARCH_URL,
    component: <ManualsAndCodeSearch />,
  },
  // {
  //   path: OEM_360_ASSIST_URL,
  //   component: <OEM360Assist />,
  // },
  // {
  //   path: VOICE_GUIDED_FIELD_ASSISTANT_URL,
  //   component: <VoiceGuidedFieldAssistant />,
  // },
  // {
  //   path: VOICE_INSPECTION_GUIDANCE_URL,
  //   component: <VoiceInspectionGuidance />,
  // },
  {
    component: () => <Navigate to={"/"} />,
  },
];

const renderRoutes = (routes) => (
  <Switch>
    {routes.map((route, i) => {
      const Component = route.component;

      return <Route key={i} path={route.path} element={Component} />;
    })}
  </Switch>
);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
